import React from 'react'
import HeaderSection from '../HeaderSection'
import TopMainNavRenovation from '../TopMainNavRenovation'

function LandscapingS() {
    return (
        <>
            <HeaderSection
                cName='header-container'
                headerImg='images/services-header.png'
                title=''
                text=''
            />
             <div className='service-offer'>
             <div><TopMainNavRenovation /></div>
                 <h1>Landscaping and Gardening</h1>
                <h4></h4>

                <p>  </p>     


            <div className='first-service'>
                <div className='first-service-text'>
                    <h2></h2>
                    <p></p>
                </div>
                <div className='image'>
                    <img alt='' src='images/Landscaping/landscaping1.JPG' />
                    <img alt='' src='images/Landscaping/landscaping2.JPG' />
                </div>
                </div>

                <div className='first-service-reverse'>
                <div className='first-service-text'>
                    <h2></h2>
                    <p></p>

                </div>
                <div className='image'>
                    <img alt='' src='images/Landscaping/landscaping.jpeg' />
                    <img alt='' src='images/Landscaping/restored-area.jpg' />
                </div>
                </div>
    

                </div>
        </>
    )
}

export default LandscapingS
