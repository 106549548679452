import React from 'react'
import '../../App.css'
import HeaderSection from '../HeaderSection'
import PricingContent from '../PricingContent'
  

export default function Pricing() {
  return (
    <>
    <HeaderSection
      cName='header-container'
      headerImg='images/images-laundry.png'
      title=''
      text=''
    />
       
    <PricingContent />
 
  </>
  )
}
