import React from 'react';
import './App.css';
import NavBar from './Components/NavBar';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './Components/pages/Home'
import Footer from './Components/Footer'
import About from './Components/pages/About';
import Contact from './Components/pages/Contact';
import CleaningS from './Components/pages/CleaningS';
import InquireNow from './Components/pages/InquireNow';
import EventCleaningS from './Components/pages/EventCleaningS';
import HouseApartmentS from './Components/pages/HouseApartmentS';
import MovingCleanigS from './Components/pages/MovingCleaningS';
import OfficeCleanigS from './Components/pages/OfficeCleaningS';
import WindowCleaningS from './Components/pages/WindowCleaningS';
import PaintingS from './Components/pages/PaintingS';
import CarpentryS from './Components/pages/CarpentryS';
// import RenovationS from './Components/pages/RenovationS';
import LandscapingS from './Components/pages/LandscapingS';
import Pricing from './Components/pages/Pricing';
import OilTankS from './Components/pages/OilTankS';
import DemolitionS from './Components/pages/DemolitionS';
// import RemodellingS from './Components/pages/RemodellingS';
import Feedback from './Components/pages/Feedback';
import SmartOne from './Components/pages/SmartOne';
import SmartElderly from './Components/pages/SmartElderly';
import KitchenR from './Components/pages/KitchenR';
import BathroomR from './Components/pages/BathroomR';
import ScrollToTop from './Components/ScrollToTop';
import FoodD from './Components/pages/FoodDelivery';
import SubletA from './Components/pages/SubletApartment';
import ElderlyCare from './Components/pages/ElderlyCare';
import RoofingS from './Components/pages/RoofingS';
import WeeklyF from './Components/pages/WeeklyFood';
import WeekOne from './Components/pages/WeekOne';
import WeekTwo from './Components/pages/WeekTwo';
import WeekThree from './Components/pages/WeekThree';
import WeekFour from './Components/pages/WeekFour';
import PrivacyStatement from './Components/pages/PrivacyStatement';
  
 

function App() {
  return (

    <BrowserRouter>
      <ScrollToTop />
      <NavBar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/feedback' element={<Feedback />} />
        <Route path='/inquire-now' element={<InquireNow />} />
        <Route path='/cleaning-services' element={<CleaningS />} />
        <Route path='/house-apartment' element={<HouseApartmentS/>} />
        <Route path='/event-cleaning' element={<EventCleaningS/>} />
        <Route path='/moving-cleaning' element={<MovingCleanigS/>} />
        <Route path='/office-cleaning' element={<OfficeCleanigS/>} />
        <Route path='/window-cleaning' element={<WindowCleaningS/>} />
        <Route path='/painting-service' element={<PaintingS/>} />
        <Route path='/carpentry-service' element={<CarpentryS/>} />
        {/* <Route path='/renovation-service' element={<RenovationS/>} /> */}
        <Route path='/landscaping-service' element={<LandscapingS/>} />
        <Route path='/oil-tank' element={<OilTankS/>} />
        <Route path='/pricing' element={<Pricing/>} />
        <Route path='/demolition-service' element={<DemolitionS/>} />
        {/* <Route path='/remodelling-service' element={<RemodellingS/>} /> */}
        <Route path='/smart-one' element={<SmartOne/>} />
        <Route path='/smart-elderly' element={<SmartElderly/>} />
        <Route path='/kitchen-remodeling' element={<KitchenR/>} />
        <Route path='/bathroom-refurbishment' element={<BathroomR/>} />
        <Route path='/food-delivery' element={<FoodD/>} />
        <Route path='/sublet-apartment' element={<SubletA/>} />
        <Route path='/elderly-care' element={<ElderlyCare/>} />
        <Route path='/roofing-service' element={<RoofingS/>} />
        <Route path='/weeklyfoodlists' element={<WeeklyF/>} />
        <Route path='/weekone' element={<WeekOne/>} />
        <Route path='/weektwo' element={<WeekTwo/>} />
        <Route path='/weekthree' element={<WeekThree/>} />
        <Route path='/weekfour' element={<WeekFour/>} />
        <Route path='/privacy-statement' element={<PrivacyStatement/>} />




        <Route path='*' element={
              <>
               <img src='../images/oops.jpg'/>
              </>
            }
            />
      </Routes>
      <Footer />
    </BrowserRouter>

  );
}

export default App;
