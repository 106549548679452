import React, { useEffect, useState } from 'react'
import '../Cards.css'
import CardItem from '../CardItem'
import HeaderSection from '../HeaderSection'
import TopMainNavRenovation from '../TopMainNavRenovation'
import { Link } from 'react-router-dom'
import TopMainNav from '../TopMainNav'
import '../TopMainNav.css'
import '../Button.css'
import { useTranslation } from 'react-i18next'
import WeekOne from './WeekOne'
import WeekTwo from './WeekTwo'
import WeekThree from './WeekThree'
import WeekFour from './WeekFour'


const STYLES = ['btns--secondary', 'btns--outline'];

const SIZES = ['btn--medium'];

export const WeeklyF = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize
}) => {

    const {t} = useTranslation();

    const checkButtonStyle = STYLES.includes(buttonStyle)
        ? buttonStyle
        : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    return (
        <>
        <HeaderSection
            cName='header-container'
            headerImg='images/FoodDelivery/food-header.JPG'
            title=''
            text=''
        />
           
    
        <div className='service-offer'>
            <div><TopMainNav /></div>
            
                <h1> {t('food-menu.h1')} </h1>

            <Link to='/food-delivery' className='btn-link'>
                <button
                    className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                    onClick={onClick}
                    type={type}
                 >
               {t('food-menu.h2')}
                </button>
            </Link> 
            

            <WeekOne/>
            <WeekTwo/>
            <WeekThree/>
            <WeekFour/>

            <p> {t('food-menu.p1')}</p>

        </div>
        
    </>
    )
}
export default WeeklyF
