import React from 'react'
import './ServiceOffer.css'
import { Link } from 'react-router-dom'
import TopMainNav from './TopMainNav'



function EventCleaning() {
  return (
    <>

      <div className='containerC'>
        <div className='service-offer'>
        {/* <div><TopMainNav /></div> */}
          <h1>Event Cleaning</h1>

          <p>
          A big mess after an event is so stressful to clean up, however, our team is here to help you out. Our service offers an area restoration after celebrating big events such as birthday parties, weddings, gender reveals, promotions, and so on. Worry no more after the parties because we’ve got it covered to clean the entire place for you.
          </p>

          <p>What is included:  </p>
          <ul>
            <li>Remove the decorations and curtains used, unplug the lights and electronics, arrange/pile-up the chairs and tables.</li>
            <li>Clean and sanitize the entire place of the event.  </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default EventCleaning
