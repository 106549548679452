import React from 'react'
import HeaderSection from '../HeaderSection'
import TopMainNav from '../TopMainNav'
import { useTranslation } from 'react-i18next'

 
function SmartOne() {
    const {t} = useTranslation();

    return (
        <>
            <HeaderSection
                cName='header-container'
                headerImg='images/SmartHouse/smart-chart-header.png'
                title=''
                text=''
            />
            <div className='service-offer'>

                <div><TopMainNav/></div>
        
                <h1>{t('smart-house.h1')}</h1>

                <div className='first-service'>
                    <div className='first-service-text'>
                        <p>{t('smart-house.p1')}</p>
                        <p><i>{t('smart-house.p2')}</i></p>
                        <p>{t('smart-house.p3')}</p>               
                    </div>
                    <div className='image'>
                        <img alt='' src='images/SmartHouse/smart-one.jpg' />
                        <img alt='' src='images/SmartHouse/smart-one1.jpeg' />
                    </div>
                </div>

                <div className='chart-image'>
                  <img alt='' src='images/SmartHouse/smart-chart.png' />
                </div>

            </div>

        </>
    )
}

export default SmartOne
