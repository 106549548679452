import React from 'react'
import HeaderSection from '../HeaderSection'
import TopMainNavRenovation from '../TopMainNavRenovation'
import { useTranslation } from 'react-i18next'

function DemolitionS() {
    const {t} = useTranslation();

    return (
        <>
            <HeaderSection
                cName='header-container'
                headerImg='images/Demolition/demolition-header.jpeg'
                title=''
                text=''
            />

            <div className='service-offer'>
                <div><TopMainNavRenovation /></div>
                    <h1>{t('demolition.h1')}</h1>
                    <p> {t('demolition.p1')} </p>    


                <div className='first-service'>
                    <div className='first-service-text'>
                        <h2>{t('demolition.p2')}</h2>
                        <ol>
                            <li>{t('demolition.p3')}</li>
                            <li>{t('demolition.p4')}</li>
                            <li>{t('demolition.p5')}</li>
                        </ol>
                    </div>
                    <div className='image'>
                        <img alt='' src='images/Demolition/demolition-header.jpg' />
                        <img alt='' src='images/Demolition/demolition.png' />
                    </div>
                </div>


                <div className='first-service-reverse'>
                    <div className='first-service-text'>
                        <ol>
                            <li>{t('demolition.p6')}</li>
                            <li>{t('demolition.p7')}</li>
                        </ol>
                    </div>
                    <div className='image'>
                        <img alt='' src='images/Demolition/demolition2.jpg' />
                        <img alt='' src='images/Demolition/demolition4.png' />
                    </div>
                </div>

                    <h3>{t('demolition.p8')}</h3>
                    <ul>
                        <li>{t('demolition.p9')}</li>
                        <li>{t('demolition.p10')}</li>
                        <li>{t('demolition.p11')}</li>
                        <li>{t('demolition.p12')}</li>
                    </ul>

                    <p>{t('demolition.p13')}</p>


            </div>

        </>
    )
}

export default DemolitionS
