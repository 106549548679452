import React from 'react'
import HeaderSection from '../HeaderSection'
import '../Cards.css'
import CardItem from '../CardItem'
import { useTranslation } from 'react-i18next'


function About() {

  const {t} = useTranslation();

  return (
    <>
      <HeaderSection
        cName='header-container'
        headerImg='images/johan-services.png'
        title=''
        text=''
      />

      <div className='cards'>
    
            <div className='cards__container'>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                        <CardItem
                            src='images/jhs.jpg'
                            text={t('aboutus.johanP')}
                            label={t('aboutus.johan')}
                            //path='/house-apartment'
                        />
                        <CardItem
                            src='images/mission.jpg'
                            text={t('aboutus.missionP')}
                            label={t('aboutus.mission')}
                            //path='/painting-service'
                        />
             
                        <CardItem
                            src='images/vission.jpg'
                            text={t('aboutus.visionP')}
                            label={t('aboutus.vision')}
                            //path='/landscaping-service'
                        />
                       
                    </ul>  
                    
                </div>
            
                <div className='list'>

                  <h3>{t('aboutus.why')}</h3>
                  <br/>

                  <ol>
                    <li>{t('aboutus.why1')}</li>
                    <li>{t('aboutus.why2')}</li>
                    <li>{t('aboutus.why3')}</li>
                    <li>{t('aboutus.why4')}</li>
                  </ol>

                </div>
                 
                  <br/>

            </div>
        </div>


    </>
  )
}

export default About
