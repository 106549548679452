import React from 'react'
import './Modal.css'

const Modal = ({ open, onClose }) => {
  if (!open) return null;
  return (
    <div onClick={onClose} className='overlay'>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className='modalContainer'
      >
        <img src='images/FoodDelivery/food-delivery3.JPG' alt='/' />
        <div className='modalRight'>
          <p className='closeBtn' onClick={onClose}>
            X
          </p>
          <div className='content'>
            <p>Do you want a</p>
            <h1>$20 CREDIT</h1>
            <p>for your first tade?</p>
          </div>
          <div className='btnContainer'>
            <button className='btnPrimary'>
              <span className='bold'>YES</span>, I love NFT's
            </button>
            <button className='btnOutline'>
              <span className='bold'>NO</span>, thanks
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;



// import React from "react";

// const modal = {
//   position: "fixed",
//   zIndex: 1,
//   left: 0,
//   top: 0,
//   width: "100vw",
//   height: "100vh",
//   overflow: "auto",
//   backgroundColor: "rgba(0, 0, 0, 0.8)"
// };

// const close = {
//   position: "absolute",
//   top: 15,
//   right: 35,
//   color: "#f1f1f1",
//   fontSize: 40,
//   fontWeight: "bold",
//   cursor: "pointer"
// };

// const modalContent = {
//   display: "flex",
//   alignItems: "center",
//   width: "35%",
//   height: "100%",
//   margin: "auto"
// };

// export const Modal = ({ onOpen, children }) => {
//   return <div onClick={onOpen}> {children}</div>;
// };

// export const ModalContent = ({ onClose, children }) => {
//   return (
//     <div style={modal}>
//       <span style={close} onClick={onClose}>
//         &times;
//       </span>
//       <div style={modalContent}>{children}</div>
//     </div>
//   );
// };
