import React from 'react'
import '../../App.css'
import HeaderSection from '../HeaderSection'
import '../ServiceOffer.css'
import { Link } from 'react-router-dom'
import TopMainNav from '../TopMainNav'
import { useTranslation } from 'react-i18next'

  
 
export default function HouseApartmentS() {
  const {t} = useTranslation();

  return (
    <>
        <HeaderSection
          cName='header-container'
          headerImg='images/images-laundry.png'
          title=''
          text=''
        />
       
      <div className='containerC'>

        <div className='service-offer'>
          <div><TopMainNav /></div>
 
            <h1>{t('cleaning.h1')}</h1>
            <p><i> {t('cleaning.p1')} </i></p>

          <div className='first-service'>
            <div className='first-service-text'>
              <p>{t('cleaning.p2')}</p>
            </div>
            <div className='image'>
              <img alt='houseimage' src='images/Cleaning/clean3.jpeg' />
              <img alt='houseimage' src='images/Cleaning/clean2.jpeg' />
            </div>
          </div>

          <div className='first-service-reverse'>
            <div className='first-service-text'>
              <p>{t('cleaning.p3')} </p>
            </div>
            <div className='image'>
              <img alt='houseimage' src='images/Cleaning/clean6.jpeg' />
              <img alt='houseimage' src='images/Cleaning/clean4.jpeg' />
            </div>
          </div>

        </div>  
      </div>
  </>
  )
}
