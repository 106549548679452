import React from 'react'
import HeaderSection from '../HeaderSection'
import TopMainNavRenovation from '../TopMainNavRenovation'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function BathroomR() {
    const {t} = useTranslation();

    return (
        <>
            <HeaderSection
                cName='header-container'
                headerImg='images/Bathroom/bathroom-header.jpeg'
                title=''
                text=''
            />
        
                <div className='service-offer'>
                    <div><TopMainNavRenovation /></div>
                       <h1>{t('bathroom.h1')}</h1>

                    <div className='first-service-reverse'>
                        <div className='first-service-text'>
                            <p>{t('bathroom.p1')}</p>
                        </div>
                        <div className='image'>
                            <img alt='' src='images/Bathroom/bathroom.jpeg' />
                            <img alt='' src='images/Bathroom/bathroom1.jpeg' />
                        </div>
                    </div>

                    <div className='first-service'>
                        <div className='first-service-text'>
                          <p><i> {t('bathroom.p2')} </i></p>
                        </div>
                        <div className='image'>
                            <img alt='' src='images/Bathroom/bathroom2.jpeg' />
                            <img alt='' src='images/Bathroom/bathroom3.jpeg' />
                        </div>
                    </div>

                    <div className='first-service-reverse'>
                        <div className='first-service-text'>   
                            <p>{t('bathroom.p3')}</p>
                        </div>
                        <div className='image'>
                            <img alt='' src='images/Bathroom/bathroom4.jpeg' />
                            <img alt='' src='images/Bathroom/bathroom7.jpeg' />
                        </div>
                    </div>

                </div>

        </>
    )
}

export default BathroomR
