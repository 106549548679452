import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './TopMainNav.css'
import './Button.css'
import { useTranslation } from 'react-i18next'


const STYLES = ['btns--secondary', 'btns--outline'];

const SIZES = ['btn--medium'];

export const TopMainNavRenovation = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize
}) => {

    const {t} = useTranslation();

    const checkButtonStyle = STYLES.includes(buttonStyle)
        ? buttonStyle
        : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    return (
        <div className='links-services'>
         <Link to='/bathroom-refurbishment' className='btn-link'>
            <button
                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
               {t('footer.bathroom')}
            </button>
        </Link>  
        <Link to='/kitchen-remodeling' className='btn-link'>
            <button
                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
               {t('footer.kitchen')}
            </button>
        </Link>
        <Link to='/carpentry-service' className='btn-link'>
            <button
                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
              {t('footer.carpentry')}
            </button>
        </Link>
        <Link to='/demolition-service' className='btn-link'>
            <button
                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
               {t('footer.demolition')}
            </button>
        </Link>
        {/* <Link to='/landscaping-service' className='btn-link'>
            <button
                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
               Landscaping and Gardening
            </button>
        </Link> */}
        <Link to='/oil-tank' className='btn-link'>
            <button
                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
               {t('footer.oiltank')}
            </button>
        </Link>
        <Link to='/painting-service' className='btn-link'>
            <button
                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
               {t('footer.painting')}
            </button>
        </Link>
        <Link to='/sublet-apartment' className='btn-link'>
            <button
                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
               {t('footer.sublet')}
            </button>
        </Link>
        <Link to='/roofing-service' className='btn-link'>
            <button
                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
               {t('footer.roofing')}
            </button>
        </Link>
        
 
        </div>

    )
}
export default TopMainNavRenovation
