import React from 'react'
import './ServiceOffer.css'
import { Link } from 'react-router-dom'
import TopMainNav from './TopMainNav'



function MovingCleaning() {
    return (
        <>

            <div className='containerC'>
                <div className='service-offer'>
                {/* <div><TopMainNav /></div> */}
                    <h1>Moving In/Out Cleaning</h1>
                    <p>
                    If in a rush to move out and have not time to clean the place up, inquire for the Move In/Out Cleaning Service! Our team will give you the assurance to make the place clean and presentable when you bid your goodbye, and to give the new mover a fresh and warm welcome. 
                    </p>
                    <ul>
                        <li>Clean and sanitize General/ All rooms in the house.</li>
                        <li>Dust drying from top to bottom such as ceiling, walls, baseboards and moldings. </li>
                        <li>Vacuum and Wash all floors thoroughly. Use suitable cleaning materials.</li>
                        <li>Scrub and remove stains in all floors and walls in the bathroom.</li>
                        <li>Wet-wipe all the empty closet and closets, shelves and doors.</li>
                        <li>Washing all windows: Please book it separately for additional cost. </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default MovingCleaning
