import React from 'react'
import HeaderSection from '../HeaderSection'
import TopMainNavRenovation from '../TopMainNavRenovation'
import { useTranslation } from 'react-i18next'

function OilTankS() {
    const {t} = useTranslation();

    return (
        <>
            <HeaderSection
                cName='header-container'
                headerImg='images/OilTank/oil-tank-header.jpg'
                title=''
                text=''
            />

            <div className='service-offer'>
                <div><TopMainNavRenovation /></div>
                    <h1>{t('oil-tank.h1')}</h1>
                    <p>{t('oil-tank.p1')}</p>    

                <div className='first-service'>
                    <div className='first-service-text'>
                        <p>{t('oil-tank.p2')}</p> 
                        <p>{t('oil-tank.p3')}</p>
                    </div>
                    <div className='image'>
                        <img alt='' src='images/OilTank/oil-tank.jpg' />
                        <img alt='' src='images/OilTank/oil-tank1.jpg' />
                    </div>
                </div>

                <div className='first-service-reverse'>
                    <div className='first-service-text'>
                        <p>{t('oil-tank.p4')}</p>
                        <p>{t('oil-tank.p5')}</p>
                    </div>
                    <div className='image'>
                        <img alt='' src='images/OilTank/oil-tank2.jpg' />
                        <img alt='' src='images/OilTank/oil-tank3.png' />
                    </div>
                </div>

                    <h4>{t('oil-tank.p6')}</h4>
                   
                    <ol>
                        <li>{t('oil-tank.p7')}</li>
                        <li>{t('oil-tank.p8')}</li>
                        <li>{t('oil-tank.p9')}</li>
                        <li>{t('oil-tank.p10')}</li>
                        <li>{t('oil-tank.p11')}</li>
                        <li>{t('oil-tank.p12')}</li>
                    </ol>
               
                
                    <p>{t('oil-tank.p13')}</p>

            </div>


        </>
    )
}

export default OilTankS
