import React from "react";
import './Modal.css'

function Modal({ closeModal }) {
    return (
        <>
            <div className="modalBackground">
                <div className="modalContainer">
                    <div className="titleCloseBtn">
                        <button onClick={() => closeModal(false)}>X</button>
                    </div>
                    <div className="scrollContainer">
                        <div className="titleScroll">
                            <h3>General Terms and Conditions</h3>
                        </div>
                        {/* <div className="bodyScroll">
                            <ol>
                                <li>1. Agreement for the booking system, only confirmed bookings will  be prioritized. </li>
                                <li>2. Before the booking is made, the client m</li>
                                <li>3. If the client decides to move the schedule of service operation after signing the contract of agreement, the Company will impose an additional charge. </li>
                                <li>4. During the process of booking, the clients should list down all the requests and the not-to-do in the provided sheet of the form. </li>
                                <li>5. When the booking is made, the company will visit the location 5 days before the scheduled service operation. </li>
                            </ol>
                        </div>

                        <div className="titleScroll">
                            <h4>OFFERS AND SERVICES</h4>
                        </div>
                        <div className="bodyScroll">
                            <ol>
                                <li>1. The Service offers different services that provide home solutions such as  residential and commercial cleaning services, painting, carpentry, gardening, plumbing,  within the vicinity of a business office, event and a residential area.  </li>
                                <li>2. The clients can upgrade the previous service to different types of services offered by Johan Services company at least 5 working days before the schedule of service operation.  </li>
                                <li>3. After the client finishes the booking process, the staff will be deployed immediately at the agreed time to do their duties. </li>
                                <li>4. The Service provides for all the needed materials and equipment thoroughly sanitized and disinfected which are completely nonhazardous and undamaged. If the client has its own and will provide specific materials  that could be an option as well.  </li>
                                <li>5. a 10% discount will be imposed if the client has the complete cleaning materials for utility. </li>
                                <li> 6. The Service also includes backyard cleaning such as mowing the lawn, watering the flowers in the garden, and segregating the trash.</li>
                                <li>7.  Other than cleaning, The Service also offers restoration after the parties and celebrations inside the lot or the building.</li>
                            </ol>
                        </div>

                        <div className="titleScroll">
                            <h4>CANCELLATION POLICY</h4>
                        </div>
                        <div className="bodyScroll">
                            <ol>
                                <li>1. When the client has already signed the contract of agreement and canceled the services regardless of any reasons, the payment that the customer paid as down payment shall be forfeited. </li>
                                <li>2. When the cleaners are sent already to the residential lot/commercial building, the client cannot cancel the service at all cost, otherwise, the charge for this cancellation is 100%. </li>
                                <li>3. If the residential lot or commercial building is closed/locked during the schedule, the booking is considered canceled. </li>
                             </ol>
                        </div>


                        <div className="titleScroll">
                            <h4>COMPANY AND CLIENT’S AGREEMENT</h4>
                        </div>
                        <div className="bodyScroll">
                            <ol>
                                <li>1. No money-tipping shall be made between the client and the cleaners, as tips should be sent directly to the company. </li>
                                <li>2. No verbal and physical assault to the cleaners. </li>
                                <li>3. The client shall not be allowed to interrogate the personal information of the cleaners. </li>
                                <li>4. The cleaners cannot transfer the appliances, furniture, and other things inside the lot or building from one place to another (only when the client specified it during the booking). </li>
                                <li>5. The client should always direct to the Company for cleaning suggestions and not demand it to the cleaners. </li>
                                <li>6. No refunds shall be made if the client is dissatisfied with the normal service. </li>
                                <li>7. The client shall has the right report the cleaners for laziness, stealing, and poor performance.</li>
                                <li>8.  The Company is responsible for paying the client all the damages made by a/the cleaner/s.</li>
                                <li>9. All the complaints and concerns should only be accommodated within 24 hours after the cleaning operation is done.</li>
                                <li>10. As per the Company’s regulation, the cleaners should have a 30-minute break in the whole time duration of operating hours. </li>
                            </ol>
                        </div> */}

                        <div className="footer">
                            <button onClick={() => closeModal(false)} id="cancelBtn">Close</button>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Modal