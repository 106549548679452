import React from 'react'
import HeaderSection from '../HeaderSection'
import TopMainNavRenovation from '../TopMainNavRenovation'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'


function KitchenR() {
    const {t} = useTranslation();

    return (
        <>
            <HeaderSection
                cName='header-container'
                headerImg='images/Kitchen/kitchen-header.jpeg'
                title=''
                text=''
            />
               
        
                <div className='service-offer'>
                    <div><TopMainNavRenovation /></div>
                        <h1>{t('kitchen.h1')}</h1>

                    
                    <div className='first-service'>
                        <div className='first-service-text'>
                            <p>{t('kitchen.p1')}</p>
                        </div>
                        <div>
                        <ReactPlayer 
                        onReady={true}
                        url='https://youtube.com/shorts/COgOqL2aZsg?feature=share'  />
                        </div>
                    </div>

                    <div className='first-service-reverse'>
                    <div className='image'>
                            <img alt='' src='images/Kitchen/kitchen.jpeg' />
                            <img alt='' src='images/Kitchen/kitchen2.jpeg' />
                        </div>
                        <div className='image'>
                                <img alt='' src='images/Kitchen/kitchen4.jpeg' />
                                <img alt='' src='images/Kitchen/kitchen5.jpeg' />
                        </div>
                    </div>

                   
                </div>


        </>
    )
}

export default KitchenR
