import React, { useEffect, useState } from 'react'
import '../Cards.css'
import CardItem from '../CardItem'
import HeaderSection from '../HeaderSection'
import TopMainNavRenovation from '../TopMainNavRenovation'
import { Link } from 'react-router-dom'
import TopMainNav from '../TopMainNav'
import '../TopMainNav.css'
import '../Button.css'
import { useTranslation } from 'react-i18next'


const STYLES = ['btns--secondary', 'btns--outline'];

const SIZES = ['btn--medium'];

export const WeekThree = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize
}) => {

    const {t} = useTranslation();

    const checkButtonStyle = STYLES.includes(buttonStyle)
        ? buttonStyle
        : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    return (
        <>

            <div className='cards'>
                <div className='cards__container'>
                    <div className='cards__wrapper'>
                   
                    <h4>{t('food-menu.weekthree')}</h4>

                        <ul className='cards__items'>
                            <CardItem
                                src='images/FoodDelivery/pineapple-chicken.jpeg'
                                text= {t('food-menu.pineapple-chicken')}
                            />
                            <CardItem
                                src='images/FoodDelivery/carbonara.jpeg'
                                text={t('food-menu.spaghetti-carbonara')}
                            />  
                              
                              <CardItem
                                src='images/FoodDelivery/chicken-curry.jpeg'
                                text={t('food-menu.chicken-curry')}
                             />  
                            <CardItem
                                src='images/FoodDelivery/food.jpeg'
                                text={t('food-menu.beef-stir')}
                            />  
                            <CardItem
                                src='images/FoodDelivery/food.jpeg'
                                text={t('food-menu.pork-afritada')}
                            />
                        </ul>
                     
                    
                    </div>
                </div>
            </div>

        
    </>
    )
}
export default WeekThree
