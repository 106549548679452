import React, { useEffect, useState } from 'react'
import '../Cards.css'
import HeaderSection from '../HeaderSection'
import TopMainNavRenovation from '../TopMainNavRenovation'
import { Link } from 'react-router-dom'
import TopMainNav from '../TopMainNav'
import '../TopMainNav.css'
import '../Button.css'
import { useTranslation } from 'react-i18next'
import Modal from '../../Modal'
import CardItemFood from '../CardItemFood'
import '../../App.css'

const STYLES = ['btns--secondary', 'btns--outline'];

const SIZES = ['btn--medium'];

export const WeekOne = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize
}) => {

  const [openModal, setOpenModal] = useState(false)

    const {t} = useTranslation();

    const checkButtonStyle = STYLES.includes(buttonStyle)
        ? buttonStyle
        : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    return (
        <>
       
           
    
       
            <div className='cards'>
                <div className='cards__container'>
                    <div className='cards__wrapper'>

                        <h4>{t('food-menu.weekone')}</h4>

                   
                        <ul className='cards__items'> 
                             <CardItemFood 
                                src='images/FoodDelivery/fish-curry.jpeg'
                                text={t('food-menu.fish-curry')}
                                // path= {() => setOpenModal(true)}
                                id= '1'
                                />     
                      
                     
                            <CardItemFood
                                src='images/FoodDelivery/chicken-teriyaki.jpg'
                                text={t('food-menu.chicken-teriyaki')}
                                // path= {() => setOpenModal(true)}

                            />

                            <CardItemFood
                                src='images/FoodDelivery/pork-adobo.jpeg'
                                text= {t('food-menu.pork-adobo')}
                            />
                            <CardItemFood
                                src='images/FoodDelivery/tomato-soup.jpg'
                                text={t('food-menu.tomato-soup')}
                            />  
                            <CardItemFood
                                src='images/FoodDelivery/sweet-sour.jpg'
                                text={t('food-menu.sweet-sour')}
                             />  

                        </ul>
                     
                        <Modal open={openModal} onClose={() => setOpenModal(false) }  />

                        {/* {isOpen && (
                            <ModalContent onClose={() => setIsopen(false)}>
                            <img src="images/FoodDelivery/fish-curry.jpeg" />
                            </ModalContent>
                        )}
                       */}
                     
                    
                    </div>
                </div>
 
        </div>
        
    </>
    )
}
export default WeekOne
