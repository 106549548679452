import React from 'react'
import '../../App.css'
import HeaderSection from '../HeaderSection'
import MovingCleaning from '../MovingCleaning'
  

export default function MovingCleanigS() {
  return (
    <>
    <HeaderSection
      cName='header-container'
      headerImg='images/images-laundry.png'
      title=''
      text=''
    />
       
    <MovingCleaning  />
 
  </>
  )
}
