import React from 'react'
import HeaderSection from '../HeaderSection'
import TopMainNavRenovation from '../TopMainNavRenovation'
import '../ServiceOffer.css'
import { useTranslation } from 'react-i18next'



function PaintingS() {
    const {t} = useTranslation();

    return (
        <>
            <HeaderSection
                cName='header-container'
                headerImg='images/Painting/painting-header.jpg'
                title=''
                text=''
            />
        
            <div className='service-offer'>
                <div><TopMainNavRenovation /></div>
                    <h1>{t('painting.h1')}</h1>
                    <h4>{t('painting.p1')}</h4>
                    <p>{t('painting.p2')}</p>

                <div className='first-service'>
                    <div className='first-service-text'>
                        <h2>{t('painting.p3')}</h2>
                        <p>{t('painting.p4')}</p>
                    </div>
                    <div className='image'>
                        <img alt='' src='images/Painting/interior-painting.png' />
                        <img alt='' src='images/Painting/interior-painting1.png' />
                    </div>
                </div>

                <div className='first-service-reverse'>
                    <div className='first-service-text'>
                        <h2>{t('painting.p5')}</h2>
                        <p>{t('painting.p6')}</p>
                    </div>
                    <div className='image'>
                        <img alt='' src='images/Painting/exterior-painting.png' />
                        <img alt='' src='images/Painting/exterior-painting1.jpg' />
                    </div>
                </div>


                <div className='first-service'>
                    <div className='first-service-text'>
                        <h2>{t('painting.p7')}</h2>
                        <p>{t('painting.p8')}</p>
                    </div>
                    <div className='image'>
                        <img alt='' src='images/Painting/color-painting.png' />
                        <img alt='' src='images/Painting/painting2.jpg' />
                    </div>
                </div>

                <br/>
                    <h4>{t('painting.p9')}</h4>
                    <p>{t('painting.p10')}</p>
                    <h4>{t('painting.p11')}</h4>
                    <p>{t('painting.p12')} </p>
                    <p>{t('painting.p13')}</p>

            </div>

        </>
    )
}

export default PaintingS
