import React from 'react'
import './ServiceOffer.css'
import { Link } from 'react-router-dom'
import TopMainNav from './TopMainNav'



function PricingContent() {
    return (
        <>

            <div className='containerC'>
                <div className='service-offer'>
                <div><TopMainNav /></div>
                    <h1>Pricing</h1>
                    <p>
                        The pricing will depend on the size of the house, how often the customer needs cleaning and the cleaning needs of the clients. It will be calculated as follows plus travel and cleaning  supplies expenses in addition.
                    </p>
                    <p>
                        Total number of hours + travel and cleaning supplies expenses = Total payment
                    </p>
                </div>

                <div className='service-offer'>
                    <h3>Residential and Commercial Pricing</h3>
                   
                    <table className='table-center'>
                        <tr>
                            <th>Apartment/ House Size</th>
                            <th>Cleaning Duration</th>
                        </tr>
                        <tr>
                            <td>50 m²</td>
                            <td>2,5 hours</td>
                        </tr>
                        <tr>
                            <td>100 m²</td>
                            <td>3,5 hours</td>
                        </tr>
                        <tr>
                            <td>150 m²</td>
                            <td>4,5 hours</td>
                        </tr>
                        <tr>
                            <td>200  m²</td>
                            <td>6 hours</td>
                        </tr>
                    </table>
                   

                    <p>How often should we offer Cleaning Services?</p>
                    <table className='table-center'>
                        <tr>
                            <th>Weekly</th>
                            <td>499 kr/ h</td>
                        </tr>
                        <tr>
                            <th>Every other week</th>
                            <td>519  kr/ h</td>
                        </tr>
                        <tr>
                            <th>Monthly </th>
                            <td>529 kr/ h</td>
                        </tr>
                        <tr>
                            <th>One-time Cleaning </th>
                            <td>599 kr/ h</td>
                        </tr>
                    </table>
                </div>


                <div className='service-offer'>
                    <h3>Commercial Pricing</h3>
                    <h4>Market</h4>
                    <p>
                    Johan Cleaning Services will primarily market both Residential and Commercial services. Residential cleaning services can be private households, landlords, renters, homeowners, elderly, individuals or groups of people with special needs that don’t have time to clean or fix the things in their homes. Commercial services are provided to businesses such as offices, restaurants and business establishments. In addition, we could also offer our services to non-profit organizations such as public and private schools, churches, public and private clinics, public hospitals, political organizations and volunteer services organizations. 
                    </p>
                </div>



            </div>
        </>
    )
}

export default PricingContent
