import React from 'react'
import HeaderSection from '../HeaderSection'
import TopMainNav from '../TopMainNav'
import { useTranslation } from 'react-i18next'


function SmartElderly() {
    const {t} = useTranslation();

    return (
        <>
            <HeaderSection
                cName='header-container'
                headerImg='images/SmartSafe/smart-header.png'
                title=''
                text=''
            />
            <div className='service-offer'>
                <div><TopMainNav/></div>
                    <h1>{t('smart-elderly.h1')}</h1>
              
                <div className='first-service'>
                    <div className='first-service-text'>
                        <p>{t('smart-elderly.p1')}</p>
                    </div>

                    <div className='image'>
                        <img alt='' src='images/SmartSafe/smart-inge1.jpeg' />
                        <img alt='' src='images/SmartSafe/smart-inge4.jpeg' />
                    </div>
    
                </div>

                <div className='first-service-reverse'>
                    <div className='first-service-text'>
                        <p>{t('smart-elderly.p2')} </p>
                        <p>{t('smart-elderly.p3')}</p>     
                    </div>
        
                    <div className='image'>
                        <img alt='' src='images/SmartSafe/smart-camera.png' />
                        <img alt='' src='images/SmartSafe/smart-sensors.png' />
                    </div>
                    
                </div>

            </div>
           

        </>
    )
}

export default SmartElderly
