import React from 'react'
import '../../App.css'
import HeaderSection from '../HeaderSection'
import ContentCleaning from '../ContentCleaning'
import TopMainNav from '../TopMainNav'


export default function CleaningS() {
  return (
    <>
      <HeaderSection
        cName='header-container'
        headerImg='images/images-laundry.png'
        title=''
        text=''
      />
      <div className='containerC'>
        <div><ContentCleaning /></div>
      </div>

    </>
  )
}
