import React from 'react'
import './Cards.css'
import CardItem from './CardItem'
import { useTranslation } from 'react-i18next'


export default function Cards() {

    const {t} = useTranslation();


    return (
        <div className='cards'>
            <h1>{t('cards.h1')}</h1>
            <div className='cards__container'>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                         {/* <CardItem
                            src='images/FoodDelivery/food-delivery2.jpeg'
                            text={t('cards.foodtext')}
                            label={t('footer.food')}
                            path='/food-delivery'
                        /> */}
                        <CardItem
                                src='images/Elderlycare/elderlycare-header.jpeg'
                                text={t('cards.elderlytext')}
                                label={t('footer.elderly')}
                                path='/elderly-care'
                            />  
                            <CardItem
                                src='images/images-apartment.png'
                                text={t('cards.cleaningtext')}
                                label={t('footer.cleaning')}
                                path='/house-apartment'
                            />
                        
                    </ul>
                     <ul className='cards__items'> 
                            
                             <CardItem
                                src='images/SmartHouse/smart-chart-header.png'
                                text={t('cards.savingtext')}
                                label={t('footer.saving')}
                                path='/smart-one'
                            />
                            <CardItem
                                src='images/SmartSafe/smart-inge.jpeg'
                                text={t('cards.livingtext')}
                                label={t('footer.living')}
                                path='/smart-elderly'
                            />
                             <CardItem
                                src='images/SubletApartment/sublet-apartment.png'
                                text={t('cards.sublettext')}
                                label={t('footer.sublet')}
                                path='/sublet-apartment'
                            />
                        </ul>
                        <ul className='cards__items'> 
                        <CardItem
                            src='images/images-carpentry.jpg'
                            text={t('cards.carpentrytext')}
                            label={t('footer.carpentry')}
                            path='/carpentry-service'
                        />
                        <CardItem
                            src='images/Painting/exterior-painting1.jpg'
                            text={t('cards.paintingtext')}
                            label={t('footer.painting')}
                            path='/painting-service'
                        />
                        <CardItem
                           src='images/Kitchen/kitchen-header.jpeg'
                           text={t('cards.kitchentext')}
                           label={t('footer.kitchen')}
                           path='/kitchen-remodeling'
                       />
                             
                        </ul>
                    <ul className='cards__items'>
                        <CardItem
                           src='images/OilTank/oil-tank.jpg'
                           text={t('cards.oiltanktext')}
                           label={t('footer.oiltank')}
                           path='/oil-tank'
                       />
                        <CardItem
                           src='images/Demolition/demolition.png'
                           text={t('cards.demolitiontext')}
                           label={t('footer.demolition')}
                           path='/demolition-service'
                       />
                       
                        <CardItem
                          src='images/Roofing/roofing-header.jpg'
                          text={t('cards.roofingtext')}
                          label={t('footer.roofing')}
                          path='/roofing-service'
                      />  
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                          src='images/Bathroom/bathroom-header.jpeg'
                          text={t('cards.bathroomtext')}
                          label={t('footer.bathroom')}
                          path='/bathroom-refurbishment'
                      />  
                        {/* <CardItem
                            src='images/images-window.png'
                            text='Crystal Clear Views: Your Premier Window Cleaning Service'
                            label='Window Cleaning'
                            path='/window-cleaning'
                        /> */}
                      {/* <CardItem
                            src='images/images-renovation.png'
                            text='Renovate, Restore, Revive: Your Trusted Renovation and Refurbishment Partner'
                            label='Renovation and Refurbishment'
                            path='/renovation-service'
                        /> */}
                    </ul>  
                    <ul className='cards__items'> 
                     
                    </ul>
                   <ul className='cards__items'> 
                     
                    </ul> 
                    <ul className='cards__items'> 
                     
                     {/* <CardItem
                         src='images/Landscaping/landscaping1.JPG'
                         text='Natures Masterpiece: Your Premier Landscaping and Gardening Service'
                         label='Landscaping and Gardening'
                         path='/landscaping-service'
                     /> */}
                  </ul>
                </div>
            </div>
        </div>
    )
}
