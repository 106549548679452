import React from 'react'
import '../../App.css'
import HeaderSection from '../HeaderSection'
import EventCleaning from '../EventCleaning'
 

export default function EventCleaningS() {
  return (
    <>
    <HeaderSection
      cName='header-container'
      headerImg='images/images-laundry.png'
      title=''
      text=''
    />
       
    <EventCleaning />
 
  </>
  )
}
