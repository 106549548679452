import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { MenuItems } from './MenuItems'
import './Dropdown.css'
import { useTranslation } from 'react-i18next'

function Dropdown() {
    

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const {t} = useTranslation();

    return (
        <>
            <ul
                onClick={handleClick}
                className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}>
                
                     
                        <li>
                            <Link
                                className= 'dropdown-link'
                                to= '/carpentry-service'
                                onClick={() => setClick(false)
                                }>
                               {t('navbar.construction')}
                                
                            </Link>
                        </li>
                        <li>
                            <Link
                                className= 'dropdown-link'
                                to= '/house-apartment'
                                onClick={() => setClick(false)
                                }>
                               {t('navbar.house')}
                                
                            </Link>
                         
                        </li>
               
               
               
            </ul>
        </>
    )

} export default Dropdown;