import React from 'react'
import './ServiceOffer.css'
import { Link } from 'react-router-dom'
import TopMainNav from './TopMainNav'



function OfficeCleaning() {
    return (
        <>

            <div className='containerC'>
                <div className='service-offer'>
                {/* <div><TopMainNav /></div> */}
                    <h1>Office and Staircase Cleaning </h1>
                    <p>
                        We clean and sanitize offices thoroughly to make your working space as clean and functional as possible. The cleanliness of the office reflects how well and productive it operates. We are here to help you have a clean and healthy environment that will meet your needs and budget. Johan Services uses a detailed and thorough cleaning system so we can keep your space and surroundings clean.
                    </p>
                    <p>
                        Johan Company deploys experienced and well-trained cleaners for this service to offices and work locations to perform even a thorough staircase cleaning. This also includes cleaning the office room, conference room, kitchen and lunch counter and comfort room. The company shall provide some of the necessary cleaning materials such as microfiber cloth, eco-friendly cleaning liquid, sponges and other supplies. The office must provide the vacuum cleaner, bucket and mopstick. Note: The water supply must be accessible by the cleaner.
                    </p>
                </div>

                <div className='service-offer'>
                    <h2> OFFICE ROOM </h2>
                    <ul>
                        <li>Sanitize tables, desks and all surfaces within reach.</li>
                        <li>Vacuuming and cleaning all floors  </li>
                        <li>Remove stains from doorknobs, door frames and light switches. </li>
                        <li>Mirrors and glass doors that are reachable 180 cm. </li>
                        <li>Emptying bins and replacing trash bags (The office must provide access to containers and access to water supplies.)</li>
                    </ul>
                </div>

                <div className='service-offer'>
                    <h2>OFFICE KITCHEN AND LUNCH AREA</h2>
                    <ul>
                        <li>Sanitize kitchen/ dining tables, chairs and  all surfaces within reach.</li>
                        <li>Clean sink, faucet and kitchen counters. </li>
                        <li>Wiping the microwave,  electric kettle, dishwasher, coffee maker and toaster and fridge on the outside.</li>
                        <li>Vacuuming and cleaning all floors</li>
                        <li>Remove stains from doorknobs, door frames and light switches. </li>
                        <li>Loading and emptying the dishwasher. </li>
                        <li>Emptying bins and replacing trash bags (The office must provide access to containers and access to water supplies.)</li>
                    </ul>
            </div>

            <div className='service-offer'>
                <h2>BATHROOM</h2>
                <ul>
                    <li>Clean WC, sink and faucet</li>
                    <li>Sanitize door and door knobs</li>
                    <li>Dry clean walls and cabinets</li>
                    <li>Emptying bins and replacing garbage bags</li>
                    <li>Cleaning mirrors</li>
                    <li>Vacuum and washing floors</li>
                </ul>
            </div>

            <div className='service-offer'>
                <h2>STAIRCASE CLEANING</h2>
                <ul>
                    <li>Vacuum and wash staircase</li>
                    <li>Clean staircase railings</li>
                    <li>Removal of stains and hard floor surfaces</li>
                    <li>Remove stains on walls and dry clean walls that are reachable for cleaners, at least no over 0.5 meters.</li>
                    
                </ul>
            </div>


        </div >
        </>
    )
}

export default OfficeCleaning
