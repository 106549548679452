import React from 'react'
import HeaderSection from '../HeaderSection'
import '../../App.css'
import Cards from '../Cards'

function Home() {
  return (
    <>
      <HeaderSection
        cName='header-container'
        headerImg='images/johan-services.png'
        //title='JoHan Service'
        //text='We provide best quality service.'
        // buttonText='Schedule A Service'
        // url='/'
        // btnClass='show'
      />
      <Cards />
    </>
  )
}

export default Home
