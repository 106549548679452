import React from 'react'
import HeaderSection from '../HeaderSection'
import { Link } from 'react-router-dom'
import TopMainNav from '../TopMainNav'
import { useTranslation } from 'react-i18next'



function ElderlyCare() {
    const {t} = useTranslation();

    return (
        <>
            <HeaderSection
                cName='header-container'
                headerImg='images/Elderlycare/elderlycare-header.jpeg'
                title=''
                text=''
            />
            
            <div className='service-offer'>
                <div><TopMainNav/></div>
                    <h1>{t('elderly-care.h1')}</h1>
              
                <div className='first-service'>
                    <div className='first-service-text'>
                        <p><i> {t('elderly-care.p1')} </i></p>
                        <p> {t('elderly-care.p2')} </p>
                    </div>
                    <div className='image'>
                        <img alt='' src='images/Elderlycare/elderlycare2.jpeg' />
                        <img alt='' src='images/Elderlycare/elderlycare11.jpg' />
                    </div>
                </div>

                <div className='first-service-reverse'>
                    <div className='first-service-text'>
                        <p> {t('elderly-care.p3')} </p>

                        <ol>
                            <li>
                                <i> {t('elderly-care.p4')} </i> 
                                    {t('elderly-care.p5')}
                            </li>
                            <li>
                                <i> {t('elderly-care.p6')} </i> 
                                    {t('elderly-care.p7')}
                            </li>
                        </ol>
                    </div>
                    <div className='image'>
                        <img alt='' src='images/Elderlycare/elderlycare13.jpg' />
                        <img alt='' src='images/Elderlycare/elderlycare12.jpg' />
                    </div>
                </div>


                <div className='first-service'>
                    <div className='first-service-text'>
                        <p>{t('elderly-care.p8')}</p>     
                    </div>
                    <div className='image'>
                        <img alt='' src='images/Elderlycare/elderlycare7.jpg' />
                        <img alt='' src='images/Elderlycare/elderlycare6.jpg' />
                    </div>
                </div>
               
                <p> {t('elderly-care.p9')} </p>
            </div>
           

        </>
    )
}

export default ElderlyCare
