import React from 'react'
import HeaderSection from '../HeaderSection'
import TopMainNav from '../TopMainNav'


function WindowCleaningS() {
    return (
        <>
            {/* <HeaderSection
                cName='header-container'
                headerImg='images/images-laundry.png'
                title=''
                text=''
            /> */}

            <div className='service-offer'>
            {/* <div><TopMainNav /></div> */}
               <h1>Window Cleaning</h1>
                <h4></h4>

                <p>Cleaning windows is often difficult and time-consuming. We know how valuable your time is. We are ready to help you wash, disinfect and make your window shine while you are enjoying the weather and quality time with your family and friends. Get some help from our professional window cleaner and get an estimate according to your needs.</p>     


                <div className='first-service'>
                <div className='first-service-text'>
                <p>High- quality standard cleaning window process consist of the following:</p>
                    <ul>
                    <li>Scrubbing windows using suitable materials and professional equipment to remove build up grease and stains.</li>
                    <li>Window edges and frame will be wiped clean and dry  afterwards.  </li>
                 </ul>
                </div>
                <div className='image'>
                    <img alt='' src='images/images-window.png' />
                 </div>
                </div>

                {/* <div className='first-service-reverse'>
                <div className='first-service-text'>
                    <h2></h2>
                    <p></p>

                </div>
                <div className='image'>
                    <img alt='' src='images/image.png' />
                    <img alt='' src='images/image.png' />
                </div>
                </div>


                <div className='first-service'>
                <div className='first-service-text'>
                    <h2></h2>
                    <p></p>
                </div>
                <div className='image'>
                    <img alt='' src='images/image.png' />
                    <img alt='' src='images/image.png' />
                </div>
                </div>   */}
    

                </div>

            
        </>
    )
}

export default WindowCleaningS
