import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './TopMainNav.css'
import './Button.css'
import { useTranslation } from 'react-i18next'


const STYLES = ['btns--secondary', 'btns--outline'];

const SIZES = ['btn--medium'];

export const TopMainNav = ({

    children,
    type,
    onClick,
    buttonStyle,
    buttonSize
}) => {

    const {t} = useTranslation();

    const checkButtonStyle = STYLES.includes(buttonStyle)
        ? buttonStyle
        : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    return (
        <div className='links-services'>
                <Link to='/smart-one' className='btn-link'>
            <button
                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
              {t('footer.saving')}
            </button>
        </Link>
        <Link to='/smart-elderly' className='btn-link'>
            <button
                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
              {t('footer.living')}
            </button>
        </Link>
        {/* <Link to='/food-delivery' className='btn-link'>
            <button
                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
              {t('footer.food')}
            </button>
        </Link> */}
        <Link to='/elderly-care' className='btn-link'>
            <button
                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
              {t('footer.elderly')}
            </button>
        </Link>
    
        <Link to='/house-apartment' className='btn-link'>
            <button
                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
              {t('footer.cleaning')}
            </button>
        </Link>
        {/* <Link to='/event-cleaning' className='btn-link'>
            <button
                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
               Event Cleaning
            </button>
        </Link>
        <Link to='/moving-cleaning' className='btn-link'>
            <button
                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
              Moving in/out Cleaning
            </button>
        </Link>
        <Link to='/office-cleaning' className='btn-link'>
            <button
                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
               Office and Staircase Cleaning
            </button>
        </Link>
        <Link to='/window-cleaning' className='btn-link'>
            <button
                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                type={type}
            >
               Window Cleaning
            </button>
        </Link> */}
     
 
        </div>

    )
}
export default TopMainNav
