import React from 'react'
import HeaderSection from '../HeaderSection'
import InquiryMessage from '../InquiryMessage'

function InquireNow() {
  return (
    <>
      <HeaderSection
      cName='header-container'
      headerImg='images/FULL.jpg'
      text=''
      />
      <InquiryMessage/>
    </>
  )
}

export default InquireNow
