import React from 'react'
import HeaderSection from '../HeaderSection'
import { Link } from 'react-router-dom'
import '../Cards.css'
import '../Contact.css'
import CardItem from '../CardItem'
import { useTranslation } from 'react-i18next'



function PrivacyStatement() {
  const {t} = useTranslation();

  return (
    <>
      <HeaderSection
        cName='header-container'
        headerImg='images/FULL.jpg'
        title=''
        text=''
      />

      <div className='cards'>
    
        <div className='cards__container'>
            <div className='cards__wrapper'>
            <h1>{t('ps.h1')}</h1>
              <div className='privacy_page'>
                <p>{t('ps.p')}</p>             
              <ol>
                <li>{t('ps.a1')}</li>  
                <li>{t('ps.a2')}</li>  
                <li>{t('ps.a3')}</li>  
                <li>{t('ps.a4')}</li>  
                <li>{t('ps.a5')}</li>  
                <li>{t('ps.a6')}</li>  
                <li>{t('ps.a7')}</li>  
                <li>{t('ps.a8')}</li>  
                <li>{t('ps.a9')}</li>  
                <li>{t('ps.a10')}</li>  
                <li>{t('ps.a11')}</li>  
                <li>{t('ps.a12')}</li>  
                <li>{t('ps.a13')}</li>  
                <li>{t('ps.a14')}</li>  
                <li>{t('ps.a15')}</li>  
                <li>{t('ps.a16')}</li>  
              </ol>         
            </div>
            <div className='privacy_page'>
              <ol>
                <li> {t('ps.b1')}
                  <p>{t('ps.bp1')}</p>
                  <ul>
                    <li>{t('ps.bl1')}</li>
                    <li>{t('ps.bl2')}</li>
                    <li>{t('ps.bl3')}</li>
                    <li>{t('ps.bl4')}</li>
                    <li>{t('ps.bl5')}</li>
                  </ul>
                </li>

                <li> {t('ps.c2')}
                  <p>{t('ps.cp1')}</p>
                  <p>{t('ps.cp2')}</p>
                  <p>{t('ps.cp3')}</p>
                </li>

                <li> {t('ps.d3')}
                  <p>{t('ps.dp1')}</p>
                </li>

                <li> {t('ps.e4')}
                  <p>{t('ps.ep1')}</p>
                  <ul>
                   <li>{t('ps.el1')}</li>
                   <li>{t('ps.el2')}</li>
                   <li>{t('ps.el3')}</li>
                   <li>{t('ps.el4')}</li>
                   <li>{t('ps.el5')}</li>
                   <li>{t('ps.el6')}</li>
                   <li>{t('ps.el7')}</li>
                  </ul>
                </li>

                <li> {t('ps.f5')}
                  <p>{t('ps.fp1')}</p>
                </li>
                
                <li> {t('ps.g6')}
                  <p>{t('ps.gp1')}</p>
                </li>

                <li> {t('ps.h7')}
                  <p>{t('ps.hp1')}</p>
                  <ul>
                   <li>{t('ps.hl1')}</li>
                   <li>{t('ps.hl2')}</li>
                   <li>{t('ps.hl3')}</li>
                   <li>{t('ps.hl4')}</li>
                  </ul>

                  <p>{t('ps.hp2')}</p>
                  <ul>
                   <li>{t('ps.hl5')}</li>
                   <li>{t('ps.hl6')}</li>
                   <li>{t('ps.hl7')}</li>
                   <li>{t('ps.hl8')}</li>
                   <li>{t('ps.hl9')}</li>
                  </ul>
                </li>

                <li> {t('ps.i8')}
                  <p>{t('ps.ip1')}</p>
                  <ul>
                   <li>{t('ps.il1')}</li>
                   <li>{t('ps.il2')}</li>
                   <li>{t('ps.il3')}</li>
                   <li>{t('ps.il4')}</li>
                  </ul>
                </li>
                
                <li> {t('ps.j9')}
                  <p>{t('ps.jp1')}</p>
                  <p>{t('ps.jp2')}</p>
                  <p>{t('ps.jp3')}</p>
                  <p>{t('ps.jp4')}</p>
                </li>

                <li> {t('ps.k10')}
                  <p>{t('ps.kp1')}</p>
                </li>

                <li> {t('ps.l11')}
                  <p>{t('ps.lp1')}</p>
                  <ul>
                   <li>{t('ps.ll1')}</li>
                   <li>{t('ps.ll2')}</li>
                   <li>{t('ps.ll3')}</li>
                   <li>{t('ps.ll4')}</li>
                  </ul>
                  <p>{t('ps.lp2')}</p>
                </li>

                <li> {t('ps.m12')}
                  <p>{t('ps.mp1')}</p>
                </li>

                <li> {t('ps.n13')}
                  <p>{t('ps.np1')}</p>
                  <p>{t('ps.np2')}</p>
                </li>

                <li> {t('ps.o14')}
                  <p>{t('ps.op1')}</p>
                </li>

                <li> {t('ps.q15')}
                  <p>{t('ps.qp1')}</p>
                  <p>{t('ps.qp2')}</p>
                  <p>{t('ps.qp3')}</p>
                </li>

                <li> {t('ps.r16')}
                  <p>{t('ps.rp1')}</p>
                  <p>{t('ps.rp2')}</p>
                  <p>{t('ps.rp3')}</p>
                  <p>{t('ps.rp4')}</p>
                </li>



              </ol>
            </div>
            </div>
        </div>
     </div>





    </>
  )
}

export default PrivacyStatement
