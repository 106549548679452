import React from 'react'
import { Link } from 'react-router-dom'


function ReviewItem(props) {
  return (
    <>
      <li className='cards__item'>
        <Link className='cards__item__link'>
          <figure className='cards__item__paragraph-wrap'>
            <h5 className='cards__item__paragraph'>{props.paragraph}</h5>
          </figure>
          <div className='cards__item__info'>
            <hr/>
            <h5 className='cards__item__texts'>{props.text}</h5>
            <p className='cards__item__date'>{props.date}</p>

          </div>
        </Link>
      </li>
    </>
  )
}

export default ReviewItem
   