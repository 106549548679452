import React from "react";
import TopMainNav from "./TopMainNav";
  


export default function ContentCleaning() {
    return (
        <>
         <div className='service-offer'>
         <div><TopMainNav /></div>
        <h1>Residential and Commercial Cleaning</h1>
        <p>A type of cleaning that is applicable for residential cleaning. It is a home cleaning that applies regularly to priority areas such as kitchen, bathroom, bedroom, living room, laundry room and guest room and gently applies a particular treatment in order to eliminate dirt, allergens, and stains. The cleaners could also use the available cleaning machine tools such as vacuum cleaners and other cleaning materials  if it is available at the client's premises. The service will guarantee that the substances above mentioned will be removed without causing any damages.
        </p>
       </div>
         </>
    )

}