import React from 'react'
import HeaderSection from '../HeaderSection'
import TopMainNavRenovation from '../TopMainNavRenovation'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'

function CarpentryS() {
    const {t} = useTranslation();

    return (
        <>
            <HeaderSection
                cName='header-container'
                headerImg='images/services-header.png'
                title=''
                text=''
            />
        
                <div className='service-offer'>
                    <div><TopMainNavRenovation /></div>
                        <h1>{t('carpentry.h1')}</h1>
                        <p> {t('carpentry.p1')} </p>

                    <div className='first-service-reverse'>
                        <div className='first-service-text'>
                            <p>{t('carpentry.p2')}</p>
                            <p> <i>{t('carpentry.p3')} </i> </p>
                        </div>
                        <div>
                        <ReactPlayer 
                        onReady={true}
                        url='https://youtube.com/shorts/khgm9pr2vYg?si=K5LPHpr0nx_Dt-Xc'  />
                        </div>
                    </div>

                    <div className='first-service'>
                        <div className='first-service-text'>
                            <p> <i>{t('carpentry.p4')} </i> </p>
                        </div>
                        <div className='image'>
                            <img alt='' src='images/Carpentry/carpentry2.jpg' />
                            <img alt='' src='images/Carpentry/carpentry3.jpg' />
                        </div>
                    </div>


                    <div className='first-service-reverse'>
                        <div className='first-service-text'>
                            <p>{t('carpentry.p5')}</p>
                        </div>
                        <div className='image'>
                            <img alt='' src='images/Carpentry/carpentry4.jpg' />
                            <img alt='' src='images/Carpentry/carpentry1.jpeg' />
                        </div>
                    </div>
 
                </div>

        </>
    )
}

export default CarpentryS
