import React from 'react'
import HeaderSection from '../HeaderSection'
import { Link } from 'react-router-dom'
import '../Cards.css'
import '../Contact.css'
import CardItem from '../CardItem'
import { useTranslation } from 'react-i18next'



function Contact() {
  const {t} = useTranslation();

  return (
    <>
      <HeaderSection
        cName='header-container'
        headerImg='images/FULL.jpg'
        title=''
        text=''
      />

      <div className='cards'>
    
      <div className='cards__container'>
        <div className='cards__wrapper'>
            <ul className='cards__items'>
                <img src='images/slogan.png' />
                <div>
           <table className='contact-table'>
            <tr>
              <th>{t('navbar.contact')}</th>
            </tr>
            <tr>
              <h5>
              Johan Service
              </h5>
            </tr>
            <tr>
              <td>Ivar Aasens Vei 17, Oslo Norway.</td>
            </tr>
            <tr>
              <td>
                <a href='mailto:info@johanservice.no'> info@johanservice.no </a></td>
            </tr>
            <tr>
              <td>
                <a href='tel:+4798612396'>+4798612396 </a></td>
            </tr>
            <tr>
              <td>
               <Link
                class='social-icon-link facebook'
                to='https://www.facebook.com/JohanService.Oslo'
                target='_blank'
                aria-label='Facebook'
              >
                <i class='fab fa-facebook-f'>acebook</i>
              </Link>
              </td>
             </tr>

          </table>
        </div>

               
            </ul>  
                    
                </div>
            </div>
        </div>





    </>
  )
}

export default Contact
