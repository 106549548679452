import React, { useEffect, useState } from 'react'
import HeaderSection from '../HeaderSection'
import TopMainNavRenovation from '../TopMainNavRenovation'
import { Link } from 'react-router-dom'
import TopMainNav from '../TopMainNav'
import '../TopMainNav.css'
import '../Button.css'
import { useTranslation } from 'react-i18next'

const STYLES = ['btns--secondary', 'btns--outline'];

const SIZES = ['btn--medium'];

export const FoodD = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize
}) => {

    const {t} = useTranslation();

    const checkButtonStyle = STYLES.includes(buttonStyle)
        ? buttonStyle
        : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    return (
        <>
            <HeaderSection
                cName='header-container'
                headerImg='images/FoodDelivery/roasted.jpeg'
                title=''
                text=''
            />
               
        
                <div className='service-offer'>
                    <div><TopMainNav /></div>
                        <h1>{t('food-delivery.h1')}</h1>
                        <h3>
                            {t('food-delivery.h3')}
                            <a href='https://godmat-retthjem.no/'>https://godmat-retthjem.no/</a>
                        </h3>
 
                        {/* <Link to='/weeklyfoodlists' className='btn-link'>
                            <button
                                className={`btns ${checkButtonStyle} ${checkButtonSize}`}
                                onClick={onClick}
                                type={type}
                            >
                                {t('food-delivery.h2')}
                            </button>
                        </Link> 
              

                    <div className='first-service'>
                        <div className='first-service-text'>
    
                            <p> {t('food-delivery.p1')} </p>
                            <p> <i> {t('food-delivery.p2')} </i></p>
                            <p> {t('food-delivery.p3')} </p>
                        </div>

                        <div className='image'>
                            <img alt='' src='images/FoodDelivery/betasuppe-pølse.jpg' />
                            <img alt='' src='images/FoodDelivery/chicken-curry.jpeg' />
                        </div>

                    </div>

                    <div className='first-service-reverse'>
                        <div className='first-service-text'>
                            <p> <i>{t('food-delivery.p4')}</i> </p>
                            <p>{t('food-delivery.p5')} </p>
                        </div>
                        <div className='image'>
                                <img alt='' src='images/FoodDelivery/food-delivery3.JPG' />
                                <img alt='' src='images/FoodDelivery/pineapple-chicken.jpeg' />
                        </div>
                
                    </div>

                    <div className='first-service'>
                        <div className='first-service-text'>
                            <h3>{t('food-delivery.p6')} </h3>
                            <p>{t('food-delivery.p7')} </p>
                            <p>{t('food-delivery.p8')} </p>
                        </div>
                        <div className='image'>
                                <img alt='' src='images/FoodDelivery/fish-curry.jpeg' />
                                <img alt='' src='images/FoodDelivery/food-delivery4.jpeg' />
                        </div>
                        
                    </div> */}
                             
                </div>


        </>
    )
}

export default FoodD
