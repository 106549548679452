
import React from 'react'
import HeaderSection from '../HeaderSection'
import TopMainNavRenovation from '../TopMainNavRenovation'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


function RoofingS() {
    const {t} = useTranslation();

    return (
        <>
            <HeaderSection
                cName='header-container'
                headerImg='images/Roofing/roofing-header.jpg'
                title=''
                text=''
            />
               
        
                <div className='service-offer'>
                    <div><TopMainNavRenovation /></div>
                        <h1>{t('roofing.h1')}</h1>
    
                    <div className='first-service'>
                        <div className='first-service-text'>
                            <p> {t('roofing.p1')}</p>
                            <p>{t('roofing.p2')}</p>
                            <p>{t('roofing.p3')}</p>
                            <p>{t('roofing.p4')}</p>
                        </div>
                        <div className='image'>
                            <img alt='' src='images/Roofing/roofing.jpg' />
                            <img alt='' src='images/Roofing/roofing2.jpg' />  
                        </div>
                     </div>
                    
                    <p><i>  {t('roofing.p5')} <br/> <br/> {t('roofing.p6')}</i> </p>              
                
                 </div>

        </>
    )
}

export default RoofingS
