import React, { useEffect, useState } from 'react'
import '../Cards.css'
import CardItem from '../CardItem'
import HeaderSection from '../HeaderSection'
import TopMainNavRenovation from '../TopMainNavRenovation'
import { Link } from 'react-router-dom'
import TopMainNav from '../TopMainNav'
import '../TopMainNav.css'
import '../Button.css'
import { useTranslation } from 'react-i18next'


const STYLES = ['btns--secondary', 'btns--outline'];

const SIZES = ['btn--medium'];

export const WeekTwo = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize
}) => {

    const {t} = useTranslation();

    const checkButtonStyle = STYLES.includes(buttonStyle)
        ? buttonStyle
        : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    return (
        <>


            <div className='cards'>
                <div className='cards__container'>
                    <div className='cards__wrapper'>
                  
                        <h4>{t('food-menu.weektwo')}</h4>

                        <ul className='cards__items'>
                            <CardItem
                                src='images/FoodDelivery/eggplant-lasagne.jpeg'
                                text= {t('food-menu.lasagne')}
                            />
                            <CardItem
                                src='images/FoodDelivery/chicken-teriyaki.jpg'
                                text={t('food-menu.chicken-teriyaki')}
                            />  
                            <CardItem
                                 src='images/FoodDelivery/ceasar-salat.jpeg'
                                 text={t('food-menu.ceasar-salat')}
                            />  
                            <CardItem
                                src='images/FoodDelivery/sweet-sour.jpg'
                                text={t('food-menu.sweet-sour')}
                            />
                            <CardItem
                                src='images/FoodDelivery/roasted.jpeg'
                                text={t('food-menu.roasted')}
                            />  
                        </ul>
                      
                    </div>
                </div>
            </div>

  
        
    </>
    )
}
export default WeekTwo
