import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'
import './NavBar.css'
import Dropdown from './Dropdown'
import LanguageSelector from './language-selector'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

function NavBar() {

    const {t} = useTranslation();
    const handleClicks =(e) =>{
     i18next.changeLanguage(e.target.value)
    }

  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 1200) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  }

  const onMouseLeave = () => {
    if (window.innerWidth < 1200) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  }

  const showButton = () => {
    if (window.innerWidth <= 1200) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>

      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <div className="logo-image">
              <img src="images/jh-logos.png" class="img-fluid" />
            </div>
            {/* <div>JoHan Service</div> */}
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                {t('navbar.home')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/about'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                {t('navbar.about')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/contact'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                {t('navbar.contact')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/feedback'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                {t('navbar.feedback')}
              </Link>
            </li>
            <li className='nav-item'
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <Link
                to='/'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                {t('navbar.services')}
              </Link>
              {dropdown && <Dropdown />}
            </li>
            <li>
              <Link
                to='/inquire-now'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                {t('navbar.estimate')}
              </Link>
            </li>
          </ul>
          <LanguageSelector onChange= {(e) => handleClicks(e)}/>
          {button && <Button buttonStyle='btn--outline'> {t('navbar.estimate')}</Button>}
        </div>
      </nav>
    </>
  );

}

export default NavBar
