import React from 'react'
import HeaderSection from '../HeaderSection'
import TopMainNavRenovation from '../TopMainNavRenovation'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


function SubletA() {
    const {t} = useTranslation();

    return (
        <>
            <HeaderSection
                cName='header-container'
                headerImg='images/SubletApartment/sublet-apartment1.png'
                title=''
                text=''
            />
               
        
                <div className='service-offer'>
                    <div><TopMainNavRenovation /></div>
                        <h1>{t('apartment.h1')}</h1>
 
              
                    <div className='first-service'>
                        <div className='first-service-text'>
                            <p> <i>{t('apartment.p1')}</i></p>
                            <p>{t('apartment.p2')}</p>
                            <p>{t('apartment.p3')}</p>
                            <p>{t('apartment.p4')}</p>
                        </div>
                        <div className='image'>
                            <img alt='' src='images/SubletApartment/sublet-apartment.png' />
                            <img alt='' src='images/SubletApartment/sublet-apartment2.jpg' />
                        </div>
                    </div>
                </div>

        </>
    )
}

export default SubletA
